import React from 'react'
import { Layout, SEO } from '@raylink/common/components'
import DownloadCommon from './download-common'

const Download = () => {
  return (
    <Layout canonical="download.html/">
      <SEO
        title="远程控制软件官方下载_远程办公软件免费下载-RayLink远程控制软件"
        keywords="远程控制软件,远程办公软件,远程控制软件下载,远程办公软件下载,远程软件下载"
        description="RayLink远程控制软件是一款简单易用、真高清、超流畅的远程桌面工具，数秒内即可实现轻量级安装，欢迎免费下载体验"
        ogUrl="download.html"
      />

      <DownloadCommon edition="personal" />
    </Layout>
  )
}

export default Download
